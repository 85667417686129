import { useState, useEffect } from 'react';
import {
  Container,
  Card,
  Typography,
  Box,
  Grid,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
  TextField,
  Button
} from '@material-ui/core';
import useSettings from '../../hooks/useSettings';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import actionListaConsumoCreditos from 'src/actions/dashboard/actionListaConsumoCreditos';
import useAuth from 'src/hooks/useAuth';

export default function RelatorioConsumoTokens() {
  const { themeStretch } = useSettings();
  const {user} = useAuth()
  const [list, setList] = useState([]);
  const [inicio, setInicio] = useState(new Date());
  const [final, setFinal] = useState(new Date());

  const buscarConsumo = async () => {
    try {
      let fields = `?inicio=${inicio}&final=${final}&provedor=${user.provedor}`;
      const res = await actionListaConsumoCreditos(fields);
      if (res.data.data) {
        setList(res.data.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    buscarConsumo();
  }, []);


  function convertNameModel(value) {
    switch (value) {
      case 'gpt-4o-mini':
        return 'Lais IA Básica';
      case '1gpt-4o':
        return 'Lais IA Avançada';
      case 'lais-voice':
        return 'Lais Voice';
      default:
        return value;
    }
  }

  const handleBuscar = () => {
    buscarConsumo();
  };

  return (
    <Container maxWidth={themeStretch ? false : 'lg'}>
      <HeaderBreadcrumbs
        heading="Relatório de Consumo de Tokens"
        links={[{ name: 'Dashboard', href: '/' }, { name: 'Relatórios', href: '#' }, { name: 'Consumo de Tokens' }]}
      />

      <Box mb={3} display="flex" alignItems="center" gap={2}>
        <TextField
          label="Data Inicial"
          type="date"
          value={inicio}
          onChange={(e) => setInicio(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="Data Final"
          type="date"
          value={final}
          onChange={(e) => setFinal(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
        <Button variant="contained" color="primary" onClick={handleBuscar}>
          Buscar
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Cliente</TableCell>
              <TableCell>Modelo</TableCell>
              <TableCell align="right">Entrada</TableCell>
              <TableCell align="right">Saída</TableCell>
              <TableCell align="right">Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((item, index) => (
              item.modelos.map((modelo, modeloIndex) => (
                <TableRow key={`${index}-${modeloIndex}`} style={{ borderBottom: '1px solid #ddd' }}>
                  {modeloIndex === 0 && (
                    <TableCell rowSpan={item.modelos.length} component="th" scope="row" style={{ fontWeight: 'bold', backgroundColor: '#f9f9f9' }}>
                      {item.cliente}
                    </TableCell>
                  )}
                  <TableCell>{convertNameModel(modelo.modelo)}</TableCell>
                  <TableCell align="right">{modelo.somaInput}</TableCell>
                  <TableCell align="right">{modelo.somaOutput}</TableCell>
                  <TableCell align="right">{modelo.somaTotal}</TableCell>
                </TableRow>
              ))
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
