

import axios from "axios";
import Config from "../../config";

const actionAdminActionCliente = (id, data) => {
    return axios({
        method: "post",
        url: `https://api.lais.app/api//v2/admin/${id}/action`,
        data
    });   
};

export default actionAdminActionCliente;